export default [

	{
		title : 'Seiste',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/seiste@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/seiste@2x.jpg",
				"d1x" : "/2025-assets/seiste@1x.jpg",
				"mob" : "/2025-assets/seiste@1x.jpg"
			}
		},
		url : "https://seiste.com/"
	},
	{
		title : 'Life Draft',
		category : 'Media',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/lifedraft@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/lifedraft@2x.jpg",
				"d1x" : "/2025-assets/lifedraft@1x.jpg",
				"mob" : "/2025-assets/lifedraft@1x.jpg"
			}
		},
		url : "https://life.job-draft.jp/"
	},
	{
		title : 'ARC’TERYX SHINJUKU',
		category : 'Store',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/arcteryx@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/arcteryx@2x.jpg",
				"d1x" : "/2025-assets/arcteryx@1x.jpg",
				"mob" : "/2025-assets/arcteryx@1x.jpg"
			}
		},
		url : "https://shinjuku.arcteryx.jp/"
	},
	{
		title : 'PARADOX Series',
		category : 'Product',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/paradox@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/paradox@2x.jpg",
				"d1x" : "/2025-assets/paradox@1x.jpg",
				"mob" : "/2025-assets/paradox@1x.jpg"
			}
		},
		url : "https://paradox-series.com/"
	},
	{
		title : 'aircord rebranding',
		category : 'Project',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/aircord-rebranding@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/aircord-rebranding@2x.jpg",
				"d1x" : "/2025-assets/aircord-rebranding@1x.jpg",
				"mob" : "/2025-assets/aircord-rebranding@1x.jpg"
			}
		},
		url : "https://aircord.co.jp/branding/"
	},
	{
		title : 'Dig The Tea',
		category : 'Media',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/digthetea@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/digthetea@2x.jpg",
				"d1x" : "/2025-assets/digthetea@1x.jpg",
				"mob" : "/2025-assets/digthetea@1x.jpg"
			}
		},
		url : "https://digthetea.com/"
	},
	{
		title : 'CICATA',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/cicata@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/cicata@2x.jpg",
				"d1x" : "/2025-assets/cicata@1x.jpg",
				"mob" : "/2025-assets/cicata@1x.jpg"
			}
		},
		url : "https://cicata.jp/"
	},
	{
		title : 'THE RAMEN CLUB',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/ramenclub@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/ramenclub@2x.jpg",
				"d1x" : "/2025-assets/ramenclub@1x.jpg",
				"mob" : "/2025-assets/ramenclub@1x.jpg"
			}
		},
		url : "https://ramenclub.jp/"
	},
	{
		title : 'ATLE DELTA',
		category : 'Facility',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/atledelta@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/atledelta@2x.jpg",
				"d1x" : "/2025-assets/atledelta@1x.jpg",
				"mob" : "/2025-assets/atledelta@1x.jpg"
			}
		},
		url : "https://atledelta.com/"
	},
	{
		title : 'stkilda',
		category : 'Hospital',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/stkilda@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/stkilda@2x.jpg",
				"d1x" : "/2025-assets/stkilda@1x.jpg",
				"mob" : "/2025-assets/stkilda@1x.jpg"
			}
		},
		url : "https://stkildaorthodontics.com.au/"
	},
	{
		title : 'Outcrop',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/outcrop@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/outcrop@2x.jpg",
				"d1x" : "/2025-assets/outcrop@1x.jpg",
				"mob" : "/2025-assets/outcrop@1x.jpg"
			}
		},
		url : "http://outcrop.jp"
	},
	{
		title : 'qino',
		category : 'Project',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/11-qino@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/11-qino@2x.jpg",
				"d1x" : "/2024-assets/11-qino@1x.jpg",
				"mob" : "/2024-assets/11-qino@1x.jpg"
			}
		},
		url : "https://qino.jp/"
	},
	{
		title : 'Digital Garage',
		category : 'Careers',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/19-digital-garage@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/19-digital-garage@2x.jpg",
				"d1x" : "/2024-assets/19-digital-garage@1x.jpg",
				"mob" : "/2024-assets/19-digital-garage@1x.jpg"
			}
		},
		url : "https://tech.garage.co.jp"
	},
	{
		title : 'EDITORA',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/18-editora@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/18-editora@2x.jpg",
				"d1x" : "/2024-assets/18-editora@1x.jpg",
				"mob" : "/2024-assets/18-editora@1x.jpg"
			}
		},
		url : "https://editora.jp/en"
	},
	{
		title : 'Expo 2025 Signature Pavilion: Future of Life',
		category : 'Event',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/17-expo2025future-of-life@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/17-expo2025future-of-life@2x.jpg",
				"d1x" : "/2024-assets/17-expo2025future-of-life@1x.jpg",
				"mob" : "/2024-assets/17-expo2025future-of-life@1x.jpg"
			}
		},
		url : "https://expo2025future-of-life.com"
	},
	{
		title : 'Yuen Ye',
		category : 'Portfolio',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/16-yuenye@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/16-yuenye@2x.jpg",
				"d1x" : "/2024-assets/16-yuenye@1x.jpg",
				"mob" : "/2024-assets/16-yuenye@1x.jpg"
			}
		},
		url : "https://yuenye.com/"
	},
	{
		title : 'The One',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/15-the-one@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/15-the-one@2x.jpg",
				"d1x" : "/2024-assets/15-the-one@1x.jpg",
				"mob" : "/2024-assets/15-the-one@1x.jpg"
			}
		},
		url : "https://the-one.co.jp/"
	},
	{
		title : 'UUUM',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/14-uuum@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/14-uuum@2x.jpg",
				"d1x" : "/2024-assets/14-uuum@1x.jpg",
				"mob" : "/2024-assets/14-uuum@1x.jpg"
			}
		},
		url : "https://www.uuum.co.jp/"
	},
	{
		title : 'BOWTE',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/12-bowte@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/12-bowte@2x.jpg",
				"d1x" : "/2024-assets/12-bowte@1x.jpg",
				"mob" : "/2024-assets/12-bowte@1x.jpg"
			}
		},
		url : "https://www.bowte.jp/"
	},
	{
		title : 'MOIQ',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/10-moiq@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/10-moiq@2x.jpg",
				"d1x" : "/2024-assets/10-moiq@1x.jpg",
				"mob" : "/2024-assets/10-moiq@1x.jpg"
			}
		},
		url : "https://moiq.capital/?light"
	},
	{
		title : 'Infinitea',
		category : 'Project',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/9-infinitea@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/9-infinitea@2x.jpg",
				"d1x" : "/2024-assets/9-infinitea@1x.jpg",
				"mob" : "/2024-assets/9-infinitea@1x.jpg"
			}
		},
		url : "https://infinitea.jp/"
	},
	{
		title : 'eterble',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/8-eterble@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/8-eterble@2x.jpg",
				"d1x" : "/2024-assets/8-eterble@1x.jpg",
				"mob" : "/2024-assets/8-eterble@1x.jpg"
			}
		},
		url : "https://eterble.com/"
	},
	{
		title : 'Northman Hokkaido',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/7-northman@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/7-northman@2x.jpg",
				"d1x" : "/2024-assets/7-northman@1x.jpg",
				"mob" : "/2024-assets/7-northman@1x.jpg"
			}
		},
		url : "https://northman-hokkaido.com"
	},
	{
		title : 'R.L',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/6-rl-waffle@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/6-rl-waffle@2x.jpg",
				"d1x" : "/2024-assets/6-rl-waffle@1x.jpg",
				"mob" : "/2024-assets/6-rl-waffle@1x.jpg"
			}
		},
		url : "https://rl-waffle.com"
	},
	{
		title : 'Eco Pork',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/3-eco-pork@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/3-eco-pork@2x.jpg",
				"d1x" : "/2024-assets/3-eco-pork@1x.jpg",
				"mob" : "/2024-assets/3-eco-pork@1x.jpg"
			}
		},
		url : "https://eco-pork.com/"
	},
	{
		title : 'Covert AI',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/5-covert@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/5-covert@2x.jpg",
				"d1x" : "/2024-assets/5-covert@1x.jpg",
				"mob" : "/2024-assets/5-covert@1x.jpg"
			}
		},
		url : "https://www.covert.jp"
	},
	// {
	// 	title : 'NESTING',
	// 	category : 'Brand',
	// 	thumb : {
	// 		mediaType:"image",
	// 		poster : "/2024-assets/4-nesting@poster.jpg.webp",
	// 		src : {
	// 			"d2x" : "/2024-assets/4-nesting@2x.jpg",
	// 			"d1x" : "/2024-assets/4-nesting@1x.jpg",
	// 			"mob" : "/2024-assets/4-nesting@1x.jpg"
	// 		}
	// 	},
	// 	url : "https://nesting.me"
	// },
	{
		title : 'UNITE DIVISION OF ME',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/2-unite-divisionofme@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/2-unite-divisionofme@2x.jpg",
				"d1x" : "/2024-assets/2-unite-divisionofme@1x.jpg",
				"mob" : "/2024-assets/2-unite-divisionofme@1x.jpg"
			}
		},
		url : "https://unite-divisionofme.com"
	},
	{
		title : 'AIKAWA KENICHI',
		category : 'Portfolio',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/1-aikawakenichi@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/1-aikawakenichi@2x.jpg",
				"d1x" : "/2024-assets/1-aikawakenichi@1x.jpg",
				"mob" : "/2024-assets/1-aikawakenichi@1x.jpg"
			}
		},
		url : "hhttps://aikawakenichi.com/"
	},
	{
		title : 'UTOPIA AGRICULTURE',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/20-ua@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/20-ua@2x.jpg",
				"d1x" : "/2024-assets/20-ua@1x.jpg",
				"mob" : "/2024-assets/20-ua@1x.jpg"
			}
		},
		url : "https://www.utopiaagriculture.com/"
	},
	
	{
		title : 'FIL - SUMI LIMITED',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/fil-sumi@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/fil-sumi@2x.jpg",
				"d1x" : "/2025-assets/fil-sumi@1x.jpg",
				"mob" : "/2025-assets/fil-sumi@1x.jpg"
			}
		},
		url : "https://sumi.fillinglife.co/"
	},
	{
		title : 'FIL',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/fil@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/fil@2x.jpg",
				"d1x" : "/2025-assets/fil@1x.jpg",
				"mob" : "/2025-assets/fil@1x.jpg"
			}
		},
		url : "https://fillinglife.co/"
	},
	{
		title : 'DDD HOTEL',
		category : 'Facility',
		thumb : {
			mediaType:"image",
			poster : "/2025-assets/ddd@poster.jpg.webp",
			src : {
				"d2x" : "/2025-assets/ddd@2x.jpg",
				"d1x" : "/2025-assets/ddd@1x.jpg",
				"mob" : "/2025-assets/ddd@1x.jpg"
			}
		},
		url : "https://dddhotel.jp/"
	},
	{
		title : 'Newhattan',
		category : 'Brand',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/newhattan@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/newhattan@2x.jpg",
				"d1x" : "/2022-assets/newhattan@1x.jpg",
				"mob" : "/2022-assets/newhattan@1x.jpg"
			}
		},
		url : "https://newhattan.jp/"
	},
	{
		title : 'RINN',
		category : 'Media',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/rinn@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/rinn@2x.jpg",
				"d1x" : "/2022-assets/rinn@1x.jpg",
				"mob" : "/2022-assets/rinn@1x.jpg"
			}
		},
		url : "https://rinn.co.jp/"
	},
	{
		title : 'assemblogue',
		category : 'Project',
		thumb : {
			mediaType:"image",
			poster : "/2024-assets/13-assemblogue@poster.jpg.webp",
			src : {
				"d2x" : "/2024-assets/13-assemblogue@2x.jpg",
				"d1x" : "/2024-assets/13-assemblogue@1x.jpg",
				"mob" : "/2024-assets/13-assemblogue@1x.jpg"
			}
		},
		url : "https://assemblogue.net"
	},
	{
		title : 'molten MFK',
		category : 'Product',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/molten@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/molten@2x.jpg",
				"d1x" : "/2022-assets/molten@1x.jpg",
				"mob" : "/2022-assets/molten@1x.jpg"
			}
		},
		url : "https://myfootballkit.jp/"
	},
	// {
	// 	title : 'The Park',
	// 	category : 'Real Estate',
	// 	thumb : {
	// 		mediaType:"image",
	// 		poster : "/2022-assets/thepark@poster.jpg.webp",
	// 		src : {
	// 			"d2x" : "/2022-assets/thepark@2x.jpg",
	// 			"d1x" : "/2022-assets/thepark@1x.jpg",
	// 			"mob" : "/2022-assets/thepark@1x.jpg"
	// 		}
	// 	},
	// 	url : "https://theparksm.com/"
	// },
	{
		title : 'Stone and Style',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/stone-and-style@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/stone-and-style@2x.jpg",
				"d1x" : "/2022-assets/stone-and-style@1x.jpg",
				"mob" : "/2022-assets/stone-and-style@1x.jpg"
			}
		},
		url : "https://stonestyle.co.th/"
	},
	{
		title : 'UDATSU',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/udatsu@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/udatsu@2x.jpg",
				"d1x" : "/2022-assets/udatsu@1x.jpg",
				"mob" : "/2022-assets/udatsu@1x.jpg"
			}
		},
		url : "https://udatsu.co.jp/"
	},
	{
		title : 'Yagi Lab',
		category : 'Portfolio',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/yagilab@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/yagilab@2x.jpg",
				"d1x" : "/2022-assets/yagilab@1x.jpg",
				"mob" : "/2022-assets/yagilab@1x.jpg"
			}
		},
		url : "https://www.yagi.iis.u-tokyo.ac.jp/"
	},
	{
		title : 'Aethra',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/aethra@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/aethra@2x.jpg",
				"d1x" : "/2022-assets/aethra@1x.jpg",
				"mob" : "/2022-assets/aethra@1x.jpg"
			}
		},
		url : "https://www.aethra.xyz/"
	},
	{
		title : 'White',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/white@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/white@2x.jpg",
				"d1x" : "/2022-assets/white@1x.jpg",
				"mob" : "/2022-assets/white@1x.jpg"
			}
		},
		url : "https://wht.co.jp"
	},
	{
		title : 'IDENTITY',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/identity@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/identity@2x.jpg",
				"d1x" : "/2022-assets/identity@1x.jpg",
				"mob" : "/2022-assets/identity@1x.jpg"
			}
		},
		url : "https://identity.city/"
	},
	{
		title : 'kiminitou',
		category : 'Media',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/kiminitou@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/kiminitou@2x.jpg",
				"d1x" : "/2022-assets/kiminitou@1x.jpg",
				"mob" : "/2022-assets/kiminitou@1x.jpg"
			}
		},
		url : "http://kiminitou.com"
	},
	{
		title : 'TSUBAKI fm',
		category : 'Media',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/tsubakifm@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/tsubakifm@2x.jpg",
				"d1x" : "/2022-assets/tsubakifm@1x.jpg",
				"mob" : "/2022-assets/tsubakifm@1x.jpg"
			}
		},
		url : "https://tsubakifm.com/"
	},
	{
		title : 'Hishiiwa',
		category : 'Store',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/hishiiwa@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/hishiiwa@2x.jpg",
				"d1x" : "/2022-assets/hishiiwa@1x.jpg",
				"mob" : "/2022-assets/hishiiwa@1x.jpg"
			}
		},
		url : "http://hishiiwa.com/"
	},
	// {
	// 	title : 'soar',
	// 	category : 'Media',
	// 	thumb : {
	// 		mediaType:"image",
	// 		poster : "/2022-assets/soar@poster.jpg.webp",
	// 		src : {
	// 			"d2x" : "/2022-assets/soar@2x.jpg",
	// 			"d1x" : "/2022-assets/soar@1x.jpg",
	// 			"mob" : "/2022-assets/soar@1x.jpg"
	// 		}
	// 	},
	// 	url : "https://soar-world.com/"
	// },
	{
		title : 'ACRO',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/acro@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/acro@2x.jpg",
				"d1x" : "/2022-assets/acro@1x.jpg",
				"mob" : "/2022-assets/acro@1x.jpg"
			}
		},
		url : "https://www.acro-inc.co.jp/"
	},
	{
		title : 'Helixies',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/helixes@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/helixes@2x.jpg",
				"d1x" : "/2022-assets/helixes@1x.jpg",
				"mob" : "/2022-assets/helixes@1x.jpg"
			}
		},
		url : "https://helixes.co/"
	},
	// {
	// 	title : '聖林公司 SEILIN',
	// 	category : 'Corporate',
	// 	thumb : {
	// 		mediaType:"image",
	// 		poster : "/2022-assets/seilin@poster.jpg.webp",
	// 		src : {
	// 			"d2x" : "/2022-assets/seilin@2x.jpg",
	// 			"d1x" : "/2022-assets/seilin@1x.jpg",
	// 			"mob" : "/2022-assets/seilin@1x.jpg"
	// 		}
	// 	},
	// 	url : "https://www.hrm.co.jp/"
	// },
	{
		title : 'MEIHOU',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/meihou@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/meihou@2x.jpg",
				"d1x" : "/2022-assets/meihou@1x.jpg",
				"mob" : "/2022-assets/meihou@1x.jpg"
			}
		},
		url : "https://www.meihou-ac.co.jp/"
	},
	// {
	// 	title : 'fabriq',
	// 	category : 'Corporate',
	// 	thumb : {
	// 		mediaType:"image",
	// 		poster : "/2022-assets/fabriq@poster.jpg.webp",
	// 		src : {
	// 			"d2x" : "/2022-assets/fabriq@2x.jpg",
	// 			"d1x" : "/2022-assets/fabriq@1x.jpg",
	// 			"mob" : "/2022-assets/fabriq@1x.jpg"
	// 		}
	// 	},
	// 	url : "https://fabriq.co.jp/"
	// },
	{
		title : 'MU inc.',
		category : 'Corporate',
		thumb : {
			mediaType:"image",
			poster : "/2022-assets/mu@poster.jpg.webp",
			src : {
				"d2x" : "/2022-assets/mu@2x.jpg",
				"d1x" : "/2022-assets/mu@1x.jpg",
				"mob" : "/2022-assets/mu@1x.jpg"
			}
		},
		url : "https://mu-inc.net/"
	}
	// {
	// 	title : 'Awwwards Conf. TOKYO',
	// 	category : 'Event',
	// 	thumb : {
	// 		mediaType:"image",
	// 		poster : "/2022-assets/awwwardsconf@poster.jpg.webp",
	// 		src : {
	// 			"d2x" : "/2022-assets/awwwardsconf@2x.jpg",
	// 			"d1x" : "/2022-assets/awwwardsconf@1x.jpg",
	// 			"mob" : "/2022-assets/awwwardsconf@1x.jpg"
	// 		}
	// 	},
	// 	url : "https://conference.awwwards.com/tokyo/"
	// },
	// {
	// 	title : 'ORF2019',
	// 	category : 'Event',
	// 	thumb : {
	// 		mediaType:"image",
	// 		poster : "/2022-assets/orf2019@poster.jpg.webp",
	// 		src : {
	// 			"d2x" : "/2022-assets/orf2019@2x.jpg",
	// 			"d1x" : "/2022-assets/orf2019@1x.jpg",
	// 			"mob" : "/2022-assets/orf2019@1x.jpg"
	// 		}
	// 	},
	// 	url : "https://orf.sfc.keio.ac.jp/2019/"
	// }

]